@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400&display=swap');



html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Nunito', sans-serif;
  background-color: #334155;
  color: #ffffff;
}


a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}


.previous ,.next{
  background-color: #334155;
  color: #ffffff;
  display: flex;
  align-items: center;
}
.visited-link:visited {
  color: #c58af9 !important ;
}

code {
  display: inline-block; 
  width: 100px; /* Whatever. The <code>'s width will change */
}
[type='text'], [type='email'], [type='url'], [type='password'], [type='number'], [type='date'], [type='datetime-local'], [type='month'], [type='search'], [type='tel'], [type='time'], [type='week'], [multiple], textarea, select{
  background: none;
}